.topoptions {
  padding: 10px;
  border-bottom: 3px solid black;
  margin-right: auto;
  box-shadow: 0 8px 6px -5px rgb(0 0 0 / 50%);
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 0px;
}

.quiz-report-containermain {
  padding: 1%;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: auto;
  float: left;
}

.otherquiztakenheader {
  font-size: 18px;
  font-weight: bold;
  width: 40%;
  margin-bottom: 10px;
  display: flex;
  align-items: flex-end;
}

.Quizzer_info {
  display: flex;
  flex: 100%;
  align-items: baseline;
  width: 85%;
  margin-top: 15px;
  font-size: 18px;
}

.otherquiztaken {
  border-spacing: 0px;
  border-width: 0px;
  width: 100%;
  border-collapse: collapse;
}

.otherquiztaken th {
  border: 1px solid rgb(149 149 149);
  padding-top: 15px;
  vertical-align: middle;
  padding-bottom: 15px;
  font-size: 16px;
  z-index: -1;
  text-align: center;
  background-color: #f4f4f4;
}
.otherquiztaken td {
  border: 1px solid #c5c5c5;
  height: 39px;
  padding-left: 10px;
  padding-right: 10px;
}

.otherquiztaken .is_link {
  color: #551a8b;
  cursor: pointer;
}
.otherquiztaken .is_link:hover {
  text-decoration: underline;
}

.report_informationsection {
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  font-size: 17px;
}

.securityalertheader {
  display: flex;
  margin: auto;
  padding: 20px;
  width: 100%;
  justify-content: space-between;
  border: 0px !important;
  font-size: 18px;
  font-weight: bold;
  align-items: center;
}

.report-answerreport {
  border-spacing: 0px;
  font-size: 16px;
  width: 100%;
  margin: auto;
}
.report-answerreport td + td {
  width: 35%;
  vertical-align: middle;
  text-align: center;
}
.reports-questionanswer {
  display: flex;
  justify-content: left;
  counter-reset: answer-counter;
}

.reports-questionanswer > div:first-child {
  min-width: 100px;
}

.reports-questionanswer > div {
  padding: 10px;
}

.option-container label {
  font-size: 16px;
  font-weight: 500;
  opacity: 0.8;
}

.option-container label pre {
  margin-block: 0px !important;
}

.option-container label pre * {
  font-size: 16px !important;
}

.report-answerreport th {
  border: 1px solid rgb(149 149 149);
  background-color: rgb(247, 243, 243);
  padding-top: 15px;
  padding-left: 10px;
  vertical-align: middle;
  border-right: 2px solid rgb(224, 222, 222);
  padding-bottom: 15px;
  font-size: 17px;
  z-index: -1;
  text-align: center;
}

.reports-questionanswer {
  display: flex;
  justify-content: left;
  counter-reset: answer-counter;
  flex-direction: column;
}
#report_Questionandanswer {
  border-right: 1px solid lightgray;
}
.security-label-unanswered {
  background-color: #f8f8f8;
  border-color: #aaaaaa;
  color: black;
}

.assignpointstable pre,
.report-answerreport pre {
  background-color: transparent;
  border: none;
  font-family: inherit;
  font-size: 16px;
  color: inherit;
  font-size: 18px;
  white-space: break-spaces;
}

.securityalertheader .alerts-button {
  color: #f8312f;
  border: 1px solid #f8312f;
  border-radius: 20px;
  padding: 10px 20px;
  background-color: transparent;
}

.correct-answer {
  color: green !important;
  font-weight: 600;
}

.user-answer {
  color: red;
  font-weight: 600;
}
.correctanswer-result::before,
.non-graded-response-result::before {
  content: "\2714";
  /* Unicode character for checkmark */
  font-size: 18px;
  margin-right: 5px;
}

.incorrectanswer-result::before,
.skipped-answer-result::before {
  content: "\2716";
  /* Unicode character for cross */
  font-size: 18px;
  margin-right: 5px;
}

.correctanswer-result,
.non-graded-response-result {
  width: fit-content;
  margin: auto;
  border: 1px solid;
  padding: 10px;
  border-radius: 6px;
  min-width: 270px;
  text-align: center;
  background-color: #f8fff9;
  color: #10be4b;
}

.incorrectanswer-result {
  width: fit-content;
  margin: auto;
  border: 1px solid;
  padding: 10px;
  border-radius: 6px;
  min-width: 270px;
  text-align: center;
  background-color: #fff8f8;
  color: #ff0404;
}

.skipped-answer-result {
  width: fit-content;
  margin: auto;
  border: 1px solid;
  padding: 10px;
  border-radius: 6px;
  min-width: 270px;
  text-align: center;
  background-color: #f8f8f8;
  border-color: #aaaaaa;
  font-weight: 500;
  color: black;
}
.otherquiztaken td {
  text-align: center;
  padding-block: 10px;
  font-size: 14px;
}

.otherquiztaken td.quiz-title {
  white-space: normal;
}

.reports_question {
  font-size: 18.5px;
  font-weight: 500;
}

.points_of_reports {
  color: rgba(0, 0, 0, 0.849);
  font-weight: 600;
  font-size: 18px;
}

.option-container-radio {
  display: flex;
  align-items: center;
  gap: 15px;
  flex-direction: row;
  margin-bottom: 20px;
}

.option-container input {
  height: 10px;
}

.simulated-radio {
  width: 20px;
  min-width: 20px;
  height: 20px;
  min-height: 20px;
  border-radius: 50%;
  border: 2px solid #000;
  margin-right: 10px;
  background-color: #fff;
}

.simulated-radio.mrq {
  border-radius: 3px !important;
}

.option-container.correct-answer.user-answer .simulated-radio {
  background-color: green !important;
  border-color: green !important;
}
.correct-answer .semi_correct_radio {
  border-color: green;
}
.user-answer .semi_correct_radio {
  border-color: red;
  background-color: red;
}

.Question-container {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 15px;
}

.report-answerreport {
  border-left: 1px solid lightgray;
  border-right: 1px solid lightgray;
}

.report-answerreport .ans-td {
  border-bottom: 1px solid gray;
}

.simulated-radio._correct {
  /* background-color: #10be4b; */
  border-color: #10be4b;
}

.simulated-radio.skipped {
  border-color: #10be4b;
}

.simulated-radio.correct {
  border-color: #10be4b;
  background-color: #10be4b;
}

.simulated-radio.incorrect {
  background-color: #ff0404;
  border-color: #ff0404;
}

.simulated-radio.incorrect.mrq._correct {
  background-color: #10be4b;
  border-color: #10be4b;
}

.option-container-radio.incorrect {
  color: #ff0404;
}

.option-container-radio.skipped {
  color: #10be4b;
}

.option-container-radio.correct {
  color: #10be4b;
}

.option-container-radio._correct {
  color: #10be4b;
}

.tag-container.correct {
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  border: 1px solid;
  padding: 10px;
  border-radius: 6px;
  min-width: 270px;
  text-align: center;
  background-color: #f8fff9;
  color: #10be4b;
}

.tag-container.correct::before {
  content: "\2714";
  font-size: 18px;
  margin-right: 5px;
}

.tag-container.incorrect {
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  border: 1px solid;
  padding: 10px;
  border-radius: 6px;
  min-width: 270px;
  text-align: center;
  background-color: #fff8f8;
  color: #ff0404;
}

.tag-container.incorrect::before {
  content: "\2716";
  font-size: 18px;
  margin-right: 5px;
}

.tag-container.timeout {
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  border: 1px solid;
  padding: 10px;
  border-radius: 6px;
  min-width: 270px;
  text-align: center;
  background-color: #fff8f8;
  color: #ff0404;
}

.tag-container.timeout::before {
  content: "\2716";
  font-size: 18px;
  margin-right: 5px;
}

.tag-container.skipped {
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  border: 1px solid;
  padding: 10px;
  border-radius: 6px;
  min-width: 270px;
  text-align: center;
  background-color: #f8f8f8;
  border-color: #aaaaaa;
  font-weight: 500;
  color: black;
}

.tag-container.skipped::before {
  content: "\2716";
  font-size: 18px;
  margin-right: 5px;
}

.tag-container.answered {
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  border: 1px solid;
  padding: 10px;
  border-radius: 6px;
  min-width: 270px;
  text-align: center;
  background-color: #f8fff9;
  color: #10be4b;
}
.tag-container.answered::before {
  content: "\2714";
  font-size: 18px;
  margin-right: 5px;
}

.active-report {
  background-color: #f5f5f5;
  font-weight: bold;
}

.btnquiz {
  margin-right: 16px;
}

.topoption-navigation {
  display: flex;
}

.arrow-button {
  display: flex;
  align-items: center;
}

.main_heading {
  font-size: 1.7rem;
}

.quiz-title-head {
  font-size: 1.7rem;
  padding-left: 10px;
  font-weight: 800;
  display: flex;
  margin-bottom: 10px;
  color: #005284;
  align-items: flex-end;
  justify-content: center;
}
