.main-add-q-popup {
  position: fixed;
  left: 400px;
  background-color: white;
  z-index: 20;
  box-shadow: 0 0 8px #002553b0;
  height: fit-content;
  border-radius: 6px;
  width: 280px;
}

.main-add-q-popup::before {
  content: "";
  position: absolute;
  top: 8%;
  left: -9px;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-right: 15px solid white;
}
.main-add-q-popup .question-list {
  border-radius: 6px;
  list-style: none;
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin-block: 0;
}

.question-list .question-list-header {
  font-weight: 600;
  font-size: 17px;
  margin-bottom: 5px;
  margin-top: 5px;
}

.main-add-q-popup .list-item-parent {
  margin-block: 3px;
}

.main-sidebar-no-questions .main-add-q-popup .list-item {
  font-size: 16px;
  padding-block: 10px;
  padding-inline: 20px;
  cursor: pointer;
  padding-left: 12px;
  border-radius: 20px;
  background: #b4dcff80;
  border: 1px solid transparent;
  background: #052949;
  color: white;
  width: 350px;
  margin-inline: auto;
  font-size: 18px;
  transition: 0.2s;
}

.main-add-q-popup .list-item {
  font-size: 16px;
  padding-block: 5px;
  padding-inline: 10px;
  cursor: pointer;
  padding-left: 12px;
  border-radius: 5px;
}

.main-add-q-popup .list-item:hover {
  background-color: #b4dcff80;
  font-weight: 500;
}

.main-sidebar-no-questions .main-add-q-popup .list-item:hover {
  background-color: #052949;
  box-shadow: 0px 0px 10px #002553b0;
}

.main-add-q-popup .is-lock {
  background-color: #a7d6ff;
  border-radius: 5px;
}

.main-add-q-popup .lock-icon {
  float: right;
}

.main-tooltip {
  position: relative;
  z-index: 10;
  overflow: hidden;
}

.main-tooltip::before {
  content: "";
  position: absolute;
  top: 46%;
  left: -10px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid black;
}

.tooltip-popup {
  border: 1px solid black;
  background-color: white;
  width: 350px;
  max-width: fit-content;
  padding: 10px;
  border-radius: 6px;
}

.tooltip-popup .tooltip-head {
  font-weight: 600;
  margin-bottom: 20px;
}

.tooltip-popup .tooltip-body .tooltip-info {
  margin-block: 20px;
}

.tooltip-popup .tooltip-body .question {
  font-weight: 600;
  margin-bottom: 10px;
}

.tooltip-popup .tooltip-body .options textarea {
  width: 100%;
  resize: none;
  margin-block: 5px;
}
.tooltip-popup .tooltip-body .options input {
  transform: scale(1);
  margin-right: 4px;
  height: auto;
  width: auto;
}

.tooltip-popup .tooltip-body .options button {
  display: flex;
  background-color: #002653;
  color: white;
  border-radius: 6px;
  font-size: 16px;
  padding-block: 5px;
  padding-inline: 8px;
  border: none;
  margin-top: 5px;
  width: fit-content;
  margin-inline: auto;
}

.tooltip-popup .tooltip-body .options img {
  width: 100%;
  border-radius: 6px;
  margin-block: 5px;
}

.tooltip-popup .tooltip-body .options .star-div {
  display: flex;
  background-color: #a7d6ff;
  width: fit-content;
  padding: 10px;
  gap: 10px;
  border-radius: 6px;
  margin-block: 5px;
}

.tooltip-popup .tooltip-body .options .star-div {
  color: #002653;
}

.tooltip-popup .tooltip-body .options .star-div .star {
  width: 30px;
}

.tooltip-popup .tooltip-body .options .ranking-number {
  width: 40px;
}

.tooltip-popup .tooltip-body .ranking-options {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.tooltip-popup .tooltip-body .options table th {
  font-size: 14px;
  font-weight: normal;
}

.tooltip-popup .tooltip-body .options table td {
  text-align: center;
  font-size: 14px;
}

.last-selected-question {
  background-color: #b4dcff80;
  font-weight: 500;
  border-radius: 5px;
}

.last-selected-question li {
  background-color: transparent !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  opacity: 1;
}

.main-tooltip .question-drag-icon {
  margin-right: 3px;
}

@media screen and (max-width: 1500px) {
  .main-add-q-popup {
    left: 300px;
  }
}
