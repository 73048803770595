
#format-text-container #tiptap {
    padding: 20px;
    /* min-height: 100px;
    max-height: 200px; */
    overflow-y: auto;
    margin: auto;
}

/* Scrollbar for long content */
#format-text-container #tiptap::-webkit-scrollbar {
    width: 8px;
}

#format-text-container #tiptap::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 4px;
}

#format-text-container #tiptap::-webkit-scrollbar-track {
    background-color: #f9f9f9;
}

/* Title and essay styles (for prebuilt formatting options) */
#format-text-container .essay {
    font-size: 22px;
    line-height: 1.8;
    font-weight: normal;
}

#format-text-container .title {
    font-size: 36px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 16px;
    color: #222;
}

#format-text-container .paragraph {
    font-size: 20px;
    line-height: 1.8;
}

/* Text alignment */
#format-text-container .text-left {
    text-align: left;
}

#format-text-container .text-center {
    text-align: center;
}

#format-text-container .text-right {
    text-align: right;
}

#format-text-container .text-justify {
    text-align: justify;
}


/* Basic editor styles */
#format-text-container #tiptap {
    margin: 10px 20px;
    outline: none;
    margin: auto;
}

#format-text-container #tiptap:first-child {
    margin: 0;
}

/* List styles */
#format-text-container #tiptap ul,
#format-text-container #tiptap ol {
    padding: 0 1rem;
    margin: 1.25rem 1rem 1.25rem 0.4rem;
}

#format-text-container #tiptap ul {
    list-style: disc;
}

#tiptap ol {
    list-style: decimal;
}

#format-text-container #tiptap ul li p,
#format-text-container #tiptap ol li p {
    margin-top: 0.25em;
    margin-bottom: 0.25em;
}

/* Heading styles */
#format-text-container #tiptap h1,
#format-text-container #tiptap h2,
#format-text-container #tiptap h3,
#format-text-container #tiptap h4,
#format-text-container #tiptap h5,
#format-text-container #tiptap h6 {
    line-height: 1.1;
    margin-top: 2.5rem;
    text-wrap: pretty;
}

#format-text-container #tiptap h1,
#format-text-container #tiptap h2 {
    margin-top: 3.5rem;
    margin-bottom: 1.5rem;
}

#format-text-container #tiptap h1 {
    font-size: 1.7rem;
}

#format-text-container #tiptap h2 {
    font-size: 1.6rem;
}

#format-text-container #tiptap p {
    font-size: 1.2rem;
}

#format-text-container #tiptap h3 {
    font-size: 1.3rem;
}

#format-text-container #tiptap h4,
#format-text-container #tiptap h5,
#format-text-container #tiptap h6 {
    font-size: 1.2rem;
}

/* Code and preformatted text styles */
#format-text-container #tiptap code {
    background-color: #F6F2FF;
    border-radius: 0.4rem;
    color: #2E2B29;
    font-size: 1.1rem;
    padding: 0.25em 0.3em;
}

#format-text-container #tiptap pre {
    background: #2E2B29;
    border-radius: 0.5rem;
    color: white;
    font-family: 'JetBrainsMono', monospace;
    margin: 1.5rem 0;
    padding: 0.75rem 1rem;
}

#format-text-container #tiptap pre code {
    background: none;
    color: inherit;
    font-size: 1rem;
    padding: 0;
}

#format-text-container #tiptap blockquote {
    border-left: 3px solid #E7E4E2;
    margin: 1.5rem 0;
    padding-left: 1rem;
}

#format-text-container #tiptap hr {
    border: none;
    border-top: 1px solid #E7E4E2;
    margin: 2rem 0;
}