* {
  box-sizing: border-box;
  height: auto;
  width: auto;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

select,
input,
.question-title {
  font-family: "Inter", sans-serif !important;
}

.quizcontainer{
  user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

@media screen and (max-width: 991px) {
  .column {
    width: 100%;
  }

  .inside-block img {
    display: none;
  }

  .ch {
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 519px) {
  .socialdiv {
    display: inline-block;
  }
}

@media only screen and (min-width: 1222px) {
  .socialdiv {
    display: grid;
    width: 75%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 4%;
  }

  .facebook img {
    margin-right: 5px;
  }
}

@media only screen and (max-width: 1000px) {
  .socialdiv {
    display: grid;
    width: 100%;
    height: 100%;
  }

  .google img {
    margin-right: 10px;
    margin-left: 3px;
  }

  .facebook img {
    margin-right: 4px;
    margin-left: 12px;
  }

  .microsoft img {
    margin-left: 5px;
  }
}

.logintittle {
  text-align: center;
}

#form {
  text-align: center;
}

@media (min-width: 813px) and (max-width: 991px) {
  .google img {
    margin-right: 0px;
    margin-left: 0px;
  }

  .socialdiv {
    display: block;
    vertical-align: middle;
  }

  .social {
    width: 32.7%;
  }

  .facebook img {
    margin-right: 5px;
  }
}

@media (min-width: 991px) and (max-width: 1222px) {
  .social {
    width: 32%;
  }
}

.outside-column {
  width: 601px !important;
}

.outside-column {
  outline-offset: 2px;
  border: 1px solid #cecece;
  background-color: white;
  box-shadow: 5px 5px 15px 1px rgba(82, 82, 82, 0.8);
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 5px;
  height: auto;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #808080;
}

.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #808080;
}

.separator:not(:empty)::before {
  margin-right: 0.25em;
}

.separator:not(:empty)::after {
  margin-left: 0.25em;
}

.form-outline {
  width: 100%;
  position: relative;
}

#togglePassword {
  position: absolute;
  top: 28px;
  left: 432px;
}

.social {
  font-size: 1.2rem;
  padding-top: 5px;
  padding-bottom: 5px;
}

.inputtypeemail {
  width: 100%;
  padding: 10px 20px;
  height: 55px;
  margin: 11px 0 19px;
  box-sizing: border-box;
  border: 1px solid #052949;
  border-radius: 7px;
  font-size: 1rem;
  padding-bottom: 12px;
  font-size: 1rem !important;
  max-width: 435px !important;
}

.inputtypepassword {
  width: 100%;
  height: 55px;
  padding: 10px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 1px solid #052949;
  border-radius: 7px;
  font-size: 1rem;
  padding-bottom: 12px;
  font-size: 1rem !important;
  max-width: 435px !important;
}

.submitbtn {
  width: 77%;
  height: 55px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  display: table;
  background: #a5d4ff;
  border: 1px solid #052949;
  box-shadow: 0px 9px 50px rgba(165, 212, 255, 0.35);
  border-radius: 7px;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.01em;
  color: #052949;
  margin-top: 1px;
}

button {
  border-radius: 6px;
}

@media only screen and (max-width: 572px) {
  .column {
    width: 100%;
  }

  .outside-column {
    width: 100%;
  }
}

@media only screen and (min-width: 573px) and (max-width: 991px) {
  .column {
    width: 80%;
    align-items: center;
    justify-items: center;
    margin-left: 10%;
  }
}

form i {
  cursor: pointer;
}

::-ms-reveal {
  display: none;
}

@media (min-width: 992px) and (max-width: 1043px) {
  #h5 {
    font-size: 1.02rem !important;
  }
}

@media (min-width: 400px) and (max-width: 813px) {
  .facebook img {
    margin-left: 17px !important;
    margin-right: 7px !important;
  }
}

button {
  cursor: pointer;
}

.createaccount {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.01em;
  color: #052949;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 12px;
  padding-bottom: 8px;
}

.separator {
  font-style: normal;
  font-weight: 600;
  font-size: 23px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #052949;
}

.socialmediaicons {
  margin-bottom: 2%;
  color: #052949;
  text-align: left;
  background: white;
  padding-top: 7px;
  padding-bottom: 7px;
  border: 1px solid #052949;
  border-radius: 7px;
  font-weight: 400;
  font-size: 20px;
  line-height: 38px;
}

.socialdivimages {
  background: white;
  border: 1px solid white;
  height: 25px;
  display: inline-block;
  padding: 0;
  vertical-align: middle;
}

.Googleimg {
  width: 25px;
  margin-right: 8%;
  margin-left: 11%;
}

.facebookimg {
  width: 18px;
  margin-right: 11%;
  margin-left: 12%;
}

.microsoftimg {
  width: 25px;
  margin-right: 7%;
  margin-left: 12%;
}

.createaccount a {
  text-decoration: none;
  color: #008ac6;
  font-weight: 300 !important;
}

.Rememberme {
  margin-bottom: 25px;
  display: inline-flex;
  align-items: center;
  width: 87%;
}

.Forgotpassword {
  font-size: 17.15px;
  justify-content: space-around;
  width: 62%;
}

.form-check {
  width: 38%;
}

.Forgotpassword a {
  text-decoration: none;
  color: #008ac6;
  float: right;
}

#make_a_quiz_free {
  margin-left: 0%;
}

.column {
  width: 601px !important;
  height: 762px !important;
  padding: 10px;
  padding-left: 6%;
  padding-right: 5%;
  margin-top: 1%;
  margin: 0 auto;
}

.outside-column {
  outline-offset: 2px;
  border: 1px solid #cecece;
  background-color: white;
  box-shadow: 5px 5px 15px 1px rgb(82 82 82 / 80%);
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 5px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15vh;
}

.modalmain {
  background-color: rgba(5, 41, 73, 0.5);
  display: none;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 9000;
  -webkit-overflow-scrolling: touch;
}

.modal-dialog1,
.modal-dialog2,
.modal-dialog3 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-47%, -45%);
  margin: 45px auto 0;
}

.modal-close2,
.modal-close1,
.modal-close3 {
  background: transparent;
}

.container.Main_page_quotes {
  display: flex;
  margin: auto;
  width: 100%;
  background: rgb(0, 122, 255);
  background: linear-gradient(
    107deg,
    rgba(0, 122, 255, 1) 0%,
    rgba(100, 111, 255, 1) 60.65%,
    rgb(90, 57, 253) 60.65%
  );
  flex-direction: row;
  max-width: 100%;
  padding-bottom: 85px;
  height: 90vh;
  align-items: center;
  margin-bottom: 86px;
  clip-path: polygon(0% 0, 100% 0, 100% 100%, 0% 100%, 50%);
}

.alreadyhaveaccount {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.01em;
  color: #052949;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 12px;
  padding-bottom: 8px;
}

.alreadyhaveaccount a {
  text-decoration: none;
  color: #008ac6;
  font-weight: 300 !important;
}

h5#signupquickeasy {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.01em;
  color: #052949;
  text-align: center !important;
  margin-top: 17px;
  margin-bottom: 25px;
}

@media screen and (max-width: 1440px) {
  .outside-column {
    scale: 0.8;
  }
}


.format-question-title{
  margin: 5px 20px;
}