/* @import "bootstrap/scss/bootstrap.scss"; */
.main-text-formatter {
  border: 1px solid #dcdcdc;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  position: sticky;
  top: 0;
  z-index: 5;
  background-color: white;
}

.main-text-formatter .text-style,
.main-text-formatter .text-align,
.main-text-formatter .make-list,
.main-text-formatter .text-head-bottom,
.main-text-formatter .add-explain,
.main-text-formatter .image-show,
.main-text-formatter .image-show,
.main-text-formatter .color-picker {
  display: flex;
  justify-content: space-around;
  gap: 20px;
  border-inline: 1px solid #cfcfcf;
  height: 90%;
  align-items: center;
  padding-inline: 20px;
}

.main-text-formatter .last-child {
  border-right: 0;
}

.main-text-formatter .btn-with-img {
  gap: 15px;
}

.main-text-formatter .text-style {
  border-left: 0px;
  /* margin-left: 75px; */
}

.main-text-formatter .add-explain {
  border-right: 0px;
}

.main-text-formatter div * {
  cursor: pointer;
}

.active-tool-bar {
  background-color: #002653db;
  color: white;
  /* font-weight: 700; */
}

.text-format-image-tooltip {
  background-color: #002653;
  background-color: #a5d4ff;
  color: #002653;
  font-weight: 500;
  width: 300px;
  border-top: 3px solid #002653;
  padding-inline: 16px;
  padding-block: 10px;
  border-radius: 0 0 10px 10px;
  margin-top: 7px;
  /* transition: 0.1s; */
  box-shadow: 0 0 10px #a5d4ff;
}

.text-format-image-tooltip::before {
  content: "";
  position: absolute;
  top: -20px;
  left: 50%;
  margin-left: -10px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent #002653 transparent;
}

.main-text-formatter .color-picker {
  position: relative;
}

.main-text-formatter .color-picker .color-select {
  width: 20px;
  height: 20px;
  border: 1px solid black;
}

.main-text-formatter .color-picker .color-picker-selector {
  position: absolute;
  left: 0px;
  top: 45px;
  padding: 10px;
  border: 1px solid black;
  display: none;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 10px;
  border-radius: 5px;
  background-color: white;
}

.main-text-formatter .color-picker .color-picker-selector .color-picker-item {
  border: 1px solid black;
  width: 22px;
  height: 22px;
}

.main-text-formatter .color-picker:hover>.color-picker-selector {
  display: grid;
}

@media screen and (max-width: 1500px) {
  .main-text-formatter {
    justify-content: start;
    font-size: 12px;
  }
}

#global-control-group .button-group button.is-active {
  background-color: #030204;
  color: white;
}