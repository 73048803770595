/* Editor content area */
#text-editor-container {
    position: relative;
}

#text-editor-container.focusStyle {
    border: 1px solid #9d9b9b;
    border-radius: 4px;
}

#text-editor-container .tiptap {
    padding: 20px;
    min-width: 100%;
    overflow-y: auto;
    margin: auto;
    height: auto;
    user-select: text !important;
}

#text-editor-container .tiptap * {
    -webkit-user-select: all;
    /* Safari */
    -moz-user-select: all;
    /* Firefox */
    -ms-user-select: all;
    /* Internet Explorer/Edge */
    user-select: all;
}


#text-editor-container.focusStyle:focus-within {
    /* background-color: #fafafa; */
    border: 1px solid black;
    transition: all 300ms var(--tw-ease, var(--default-transition-timing-function));
}



/* Scrollbar for long content */
#text-editor-container .tiptap::-webkit-scrollbar {
    width: 8px;
}

#text-editor-container .tiptap::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 4px;
}

#text-editor-container .tiptap::-webkit-scrollbar-track {
    background-color: #f9f9f9;
}

/* Title and essay styles (for prebuilt formatting options) */
#text-editor-container .essay {
    font-size: 18px;
    line-height: 1.8;
    font-weight: normal;
}

.editor-custom-placeholder {
    font-size: 1.8rem;
    font-weight: 600;
    background-color: white;
    padding: 5px;
    opacity: 0.5;
    user-select: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: auto;
}

#text-editor-container .title {
    font-size: 36px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 16px;
    color: #222;
}

#text-editor-container .paragraph {
    font-size: 16px;
    line-height: 1.8;
}

/* Text alignment */
#text-editor-container .text-left {
    text-align: left;
}

#text-editor-container .text-center {
    text-align: center;
}

#text-editor-container .text-right {
    text-align: right;
}

#text-editor-container .text-justify {
    text-align: justify;
}


/* Basic editor styles */
#text-editor-container .tiptap {
    margin: 10px 20px;
    outline: none;
    margin: auto;
}

#text-editor-container .tiptap:first-child {
    margin: 0;
}

/* List styles */
#text-editor-container .tiptap ul,
#text-editor-container .tiptap ol {
    padding: 0 1rem;
    margin: 1.25rem 1rem 1.25rem 0.4rem;
}

#text-editor-container .tiptap ul {
    list-style: disc;
}

.tiptap ol {
    list-style: decimal;
}

#text-editor-container .tiptap ul li p,
#text-editor-container .tiptap ol li p {
    margin-top: 0.25em;
    margin-bottom: 0.25em;
}

/* Heading styles */
#text-editor-container .tiptap h1,
#text-editor-container .tiptap h2,
#text-editor-container .tiptap h3,
#text-editor-container .tiptap h4,
#text-editor-container .tiptap h5,
#text-editor-container .tiptap h6 {
    line-height: 1.1;
    margin-top: 2.5rem;
    text-wrap: pretty;
}

#text-editor-container .tiptap h1,
#text-editor-container .tiptap h2 {
    /* margin-top: 3.5rem; */
    margin: 0;
}

#text-editor-container .tiptap h1 {
    font-size: 1.7rem;
}

#text-editor-container .tiptap h2 {
    font-size: 1.5rem;
}

#text-editor-container .tiptap h3 {
    font-size: 1.4rem;
}

#text-editor-container .tiptap h4,
#text-editor-container .tiptap h5,
#text-editor-container .tiptap h6 {
    font-size: 1.3rem;
}

#text-editor-container .tiptap p {
    font-size: 1.2rem;
}

/* Code and preformatted text styles */
.tiptap code {
    background-color: #F6F2FF;
    border-radius: 0.4rem;
    color: #2E2B29;
    font-size: 1.1rem;
    padding: 0.25em 0.3em;
}

#text-editor-container .tiptap pre {
    background: #2E2B29;
    border-radius: 0.5rem;
    color: white;
    font-family: 'JetBrainsMono', monospace;
    margin: 1.5rem 0;
    padding: 0.75rem 1rem;
}

#text-editor-container .tiptap pre code {
    background: none;
    color: inherit;
    font-size: 1.1rem;
    padding: 0;
}

#text-editor-container .tiptap blockquote {
    border-left: 3px solid #E7E4E2;
    margin: 1.5rem 0;
    padding-left: 1rem;
}

#text-editor-container .tiptap hr {
    border: none;
    border-top: 1px solid #E7E4E2;
    margin: 2rem 0;
}


#text-editor-container .control-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

#text-editor-container .button-group {
    display: flex;
    gap: 10px;
    justify-content: center;
}

.quiz-title-custom-class {
    margin-top: 30px;
}

/* .button-group button.is-active {
    background-color: #ddd;
} */

#text-editor-container .button-group button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.is-editor-empty::placeholder {
    /* background-color: red; */
    /* color: black; */
    color: black !important;

}

.ProseMirror-trailingBreak {
    color: #888 !important;
    /* Adjust color as needed */
    font-style: italic;
    /* Optional: make it italic for a better placeholder look */
}

.ProseMirror.is-empty .ProseMirror-trailingBreak {
    color: #888 !important;
    /* Ensure placeholder text is visible */
}

.ProseMirror .is-empty {
    color: #888 !important;
}


p.is-editor-empty:first-child::before {
    color: var(--gray-4);
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
}