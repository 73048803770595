.main-editquiz-container {
  display: flex;
  flex: 2 5 auto;
  height: 100%;
}

/* .sidebar-main {
    width: 100%;
    flex: 300;
    border-radius: 10px;
    background-color: #005284;
    height: 100%;
    color: white;
    padding: 15px;
    -webkit-box-shadow: 5px 4px 14px 0px rgba(0, 0, 0, 0.71);
    box-shadow: 5px 4px 14px 0px rgba(0, 0, 0, 0.71);
    height: 100vh;
} */

.sidebar-main .quiz-title-container {
  font-size: 1.8rem;
  font-weight: 600;
  background-color: white;
  padding: 5px;
  color: red;
  border-radius: 6px;
  margin-top: 25px;
}

.quiz-editor-container, .start-quiz-button-editor-container{
  display: flex;
  justify-content: center;
  align-items: center;
}

.quiz-editor-container.description{
  margin-top: 20px;
}

.sidebar-add-question-button {
  font-size: 1.5rem;
  font-weight: 700;
  background: transparent;
  border: none;
  margin: 10px 0px;
  color: #54b8e9;
}

.sidebar-question-containers {
  display: flex;
  gap: 10px;
  color: white;
  font-size: 1.3rem;
  align-items: center;
  margin: 5px 10px;
}

.sidebar-question-containers .question-text {
  font-weight: 500;
  color: white;
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
}

.editing-area {
  width: 100%;
  flex: 1100;
  height: 100%;
}

.editing-area .editing-container,
.editing-area .question-editing-container {
  margin-left: auto;
  margin-right: auto;
  width: 88%;
  height: auto;
  border: 1px solid lightgrey;
  margin-top: 20px;
  padding: 20px;
}

.question-editing-container {
  border: none !important;
}

.editing-container .image-upload-button {
  border-radius: 6px;
  color: white;
  text-align: center;
  background-color: #002653;
  margin-left: auto;
  margin-right: auto;
  padding: 5px 10px;
  font-size: 17px;
  display: block;
  font-weight: 400;
  cursor: pointer;
  width: 400px;
}

.editing-container .button-title-upload-image {
  margin-top: 15px;
}

.editing-container .quiz-title-error {
  color: red;
  text-align: center;
  margin: 0;
  padding: 0;
  visibility: hidden;
}

.editing-container .visible {
  visibility: visible;
}

.editing-container .remove-image-button {
  width: fit-content;
}

.editing-container .title-editor {
  width: 80%;
  height: auto;
  border: 1px solid rgba(87, 86, 86, 0.445);
  text-align: center;
  word-wrap: break-word;
  white-space: break-spaces;
  border-radius: 3px;
  margin-bottom: 10px;
  margin: 25px auto;
  padding: 10px;
  -webkit-user-modify: read-write-plaintext-only;
}


[contenteditable][placeholder]:empty:before {
  content: attr(placeholder);
  color: #b1b1b1;
  background-color: transparent;
  text-align: left;
}

.title-editor.title {
  font-size: 1.8rem;
  font-weight: 700;
  word-break: break-all;
}
.title-editor.description {
  font-size: 1.4rem;
  text-align: left;
  font-weight: 400;
}
.title-editor.answer {
  font-size: 1.1rem;
}

.start-quiz-button-editor {
  margin: auto;
  word-break: break-word;
  margin-right: auto;
  height: auto;
  width: fit-content;
  min-width: 200px;
  background-color: #54b8e9;
  color: white;
  text-align: center;
  font-size: 23px;
  border-radius: 6px;
  border: none;
  outline: none;
  margin-top: 3%;
  padding: 6px;
}

.quiz-image-container {
  position: relative;
  margin: 10px auto;
  display: block;
  margin-inline: auto;
  max-width: 640px;
  max-height: 315;
  width: fit-content;
}

.quiz-image-container img {
  display: flex;
  margin-inline: auto;
  max-width: 640px;
  max-height: 315px;
}

.image-upload-button input {
  display: none;
}

.remove-image-button {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
}

.editing-container .not-char-allowed-msg {
  text-align: center;
  color: red;
}
